import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"

const ETextbooks = () => (
  <Layout>
    <SEO title="E-Textbooks" />
    <HeroInner
      title="E-Textbooks"
      imageAlt="E-Textbooks"
      imagePath="services/custom-services/e-textbooks/hero.jpg"
    />
    <ArticleFullWidth
      descriptions={[
        `Blend-Divine provides outstanding excellent e-Book conversion services. We are committed and dedicated towards quality e-Book conversion. With the growing demand of e-Books, publishers and independent authors are looking forward to selling their e-Books in formats like Kindle, ePub, Mobipocket, MS Reader, etc. The high quality and low cost of e-Books conversion services that we provide helping many publishers and authors sell their most of the e-Books.`,
        `Our team has the deep understanding and thorough knowledge of the e-Books conversion process. We can convert any type of Word, PDF, or other format file to ePub, PRC, Mobi, AZW, BBeB, ePDF, KF8 for iPad, Kindle, KindleFire, Nook, Sony Reader, Kobo, Andriod tablets, regardless of the number of images or other complex formatting issues. We have highly skilled team who execute these tasks professionally and in less time.`,
        `Our eBook Services work for a variety of types of publishing including: Independent Publishers, Fulfillment Houses, Contract Publishers, Subsidy Press/Vanity Publishers, and Self-Publishing authors. We are equipped to serve a wide range of publisher’s digitization their eBook Conversion needs.`,
      ]}
    />
  </Layout>
)

export default ETextbooks
